body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "dinBold";
  src: local("dinBold"), url("./fonts/din/DIN Bold.ttf") format("truetype");
  font-weight: bold;
}

.first-card {
  height: 300px;
  background-color: yellow;
  position: relative;
 
  
}


@media screen and (max-width: 750px) {
  .MuiDataGrid-filterForm {
   display: flex;
   flex-direction: column;
   position: absolute;
   width: 14.563rem;
   background-color: white;
 }

.MuiDataGrid-filterFormDeleteIcon {
 align-self: flex-end;
}
}

.file-browse-btn-div {
  margin-top: 22px;

}

.add-pop-up {
  margin-top: 7rem;
  margin-left: 35%;
  width: 700px;
  justify-content: center;
  align-items: center;
  padding: 0px !important;
  max-height: 80%;
  overflow: auto !important;
}

.file-browse-btn {
  background: #f29c07;
  color: #fff;
  border: #f29c07 2px solid;
  border-radius: 3px;
}

.file-browse-btn-div {
  margin-top: 22px;
}

.file-input {
  display: none;
}





.link {
  color: black;
  font-size: 0.8rem;
  margin-top: 1rem;
}

.notification-box {
  position: fixed;
  z-index: 2000;
  top: 10px;
  right: 10px;
  padding: 5px 15px;
  border-radius: 5px;
  /* border-top: 3px solid #b8e994;
  border-bottom: 3px solid #b8e994;
  background: #27ae60;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  animation: fade linear 5000ms;
}
@keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.notification {
  padding: 0;
  /* color: #b8e994; */
}
.notification .material-icons {
  color: #44bd32;
  font-size: 3rem;
  vertical-align: middle;
}

.error-notification-box {
  position: fixed;
  z-index: 2000;
  top: 10px;
  right: 10px;
  padding: 5px 15px;
  border-radius: 5px;
  /* border-top: 3px solid #fa8231;
  border-bottom: 3px solid #fa8231;
  background: #c0392b;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  animation: fade linear 5000ms;
}
.error-notification {
  padding: 0;
  color: #fff;
}
/* .error-notification .material-icons {
  color: #fa8231;
  font-size: 3rem;
  vertical-align: middle;
} */


/* .first-card:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  border-bottom: 80px solid white;
  border-right: 80px solid red;
  width: 0;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.cornered {
  width: 260px;
  height: 0px;
  border-top: 40px solid;
  border-right: 40px solid white;
}
  
/* .border-gradient {
  border: 10px solid;
  border-image-slice: 1;
  border-width: 5px;
} */
.btn-gradient-1 {
  border:"2px solid linear-gradient(45deg, rgba(0, 81, 122, 1) 0%, rgba(114, 178, 120, 1) 65%)" !important;
  
}

input[type="checkbox" i]{
background: red !important;
}

.MuiSlider-thumb {
  position: absolute !important;
  width: 48px !important;
  height: 48px !important;
  box-sizing: border-box !important;
  /* border-radius: 0%; */
  /* outline: 0px; */
  background-color: #ffffff00 !important;
  display: flex !important;
  -webkit-box-align: center;
  align-items: center ;
  -webkit-box-pack: center !important;
  justify-content: center ;
  /* transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, bottom 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; */
  top: 50% ;
  transform: translate(-50%, -50%) !important;
  left: 0% ;
  background-image: url("../public/favicon.ico") !important;
  background-repeat: no-repeat !important;
  background-size: 45px 45px !important ;
  border: none transparent !important;
  /* background-color: rgba(0, 0, 0, 0); */
  transition: none 0s ease 0s ;
}

.MuiSlider-thumb::before{
  box-shadow: none !important;
}


/* .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper{
  overflow-x: auto !important;
} */




.footer-page{
  background:linear-gradient(45deg, rgba(0, 81, 122, 1) 0%, rgba(114, 178, 120, 1) 65%);
 
  /* background:linear-gradient(45deg, rgba(0, 81, 122, 1) 0%, rgba(114, 178, 120, 1) 65%); */
}

 /* .css-ahj2mt-MuiTypography-root{
  color:black
 } */

/* .btn-gradient {
  border: 10px solid;
  border-image-slice: 1;
  border-width: 5px;
}
.btn-gradient-1 {
  border-color: linear-gradient(to left, #743ad5, #d53a9d);
} */
